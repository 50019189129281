import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { DefinitionOfSidelapConnectors } from './../../decking/entities/decking-code-list/enums/definition-sidelap-connectors';
import { DeckingDesignService } from './../../decking/services/decking-design/decking-design.service';

@Pipe({
    name: 'sidelapConnectorTitle',
    pure: false
})
export class SidelapConnectorTitle implements PipeTransform, OnDestroy {
    public isBySpacing$: Observable<boolean>;
    public connectorSpacingTitle = '';
    private unsubscribe$ = new Subject<void>();

    constructor(
        private deckingDesignService: DeckingDesignService,
    ) {
        const currentArea$= this.deckingDesignService.currentArea$;
        this.isBySpacing$ = currentArea$.pipe(
            map(s => s.definitionOfSidelapConnectors.id === DefinitionOfSidelapConnectors.ByConnectionSpacing),
            distinctUntilChanged()
        );

        this.isBySpacing$.pipe(
            takeUntil(this.unsubscribe$)
          ).subscribe(isBySpacing => {
            this.connectorSpacingTitle = isBySpacing ? 'FastenerSpacing' : 'SideByConnectors';
          });
    }

    transform(translationKey: string): string {
        if (!translationKey) {
            return '';
        }

        return translationKey + this.connectorSpacingTitle;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }
}
