//import { Injectable } from '@angular/core';
//import { LocalizationServiceInjected } from '@profis-engineering/pe-ui-common/services/localization.common';
import { environment } from 'src/environments/environmentDecking';
import { Injectable, OnDestroy } from '@angular/core';
import { IGetStringOptions, LocalizationServiceBase, LocalizationServiceInjected } from '@profis-engineering/pe-ui-common/services/localization.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { Observable, Subject } from 'rxjs';
import { LoggerService } from './logger.service';
import { ApiService } from './api.service';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { LanguageTranslations } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.TranslationsService.Shared.Entities';
import { HttpRequest } from '@angular/common/http';

const TranslationLibraryName = 'Decking';
const TranslationsUrl = environment.deckingTranslationServiceUrl;

@Injectable({
  providedIn: 'root'
})
export class LocalizationService extends LocalizationServiceInjected implements OnDestroy {

  private _moduleTranslations: Record<string, string> = {};
  private _moduleTranslationsLoaded = false;
  private _moduleTranslationsLoading = false;
  private _newSelectedLanguage: string;

  private _missingModuleTranslations: string[] = [];
  logMissingTranslation(translationKey: string) {
      if (!translationKey || this._missingModuleTranslations.includes(translationKey))
          return;

      this._missingModuleTranslations.push(translationKey);
      this.loggerService.log(`Missing ${TranslationLibraryName} key: ` + translationKey, LogType.warn);
  }

  public override get localizationChange(): Observable<void> {
      return this.moduleLocalizationChangeObservable;
  }

  private moduleLocalizationChange = new Subject<void>();
  private moduleLocalizationChangeObservable = this.moduleLocalizationChange.asObservable();

  constructor(
      private loggerService: LoggerService,
      private apiService: ApiService
  ) {
      super();
  }

  override setBaseService(baseService: LocalizationServiceBase): void {
      super.setBaseService(baseService);
      this.getStringBase = baseService.getString.bind(baseService);
      this.hasTranslationBase = baseService.hasTranslation.bind(baseService);
      this.getString = this.getModuleTranslation;
      this.hasTranslation = this.hasModuleOrBaseTranslation;
  }

  public get initialized(): boolean {
      return this._moduleTranslationsLoaded;
  }

  public get loading(): boolean {
    return this._moduleTranslationsLoading;
  }

  public async initTranslations(language: string) {
    this._newSelectedLanguage = language;
      await this.loadModuleTranslations({ supressErrorMessage: true });

      if (!this._moduleTranslationsLoaded) {
          throw new Error(`Failed to initialize ${TranslationLibraryName} translations.`);
      }

      this.baseService.localizationChange.subscribe((async () => {
          await this.updateModuleTranslations();
          this.moduleLocalizationChange.next();
      }) as () => void);
  }

  async updateModuleTranslations(): Promise<void> {
      if (!this._moduleTranslationsLoading)
          await this.loadModuleTranslations();
  }

  getModuleTranslation(translationKey: string, options?: IGetStringOptions | undefined): string {
      let result = this._moduleTranslations[translationKey];

      if (options?.tags) {
          result = this.sanitizeText(result, options.tags);
      }

      // if module translation is not found, return base service translation and log missing translation
      if (!result) {
          this.logMissingTranslation(translationKey);
          return this.getStringBase(translationKey, options);
      }

      return result;
  }

  hasModuleOrBaseTranslation(translationKey: string): boolean {
      if (!translationKey?.trim() || !this.getModuleKeyExists(translationKey)) {
          return false;
      }

      const translation = this.getModuleTranslation(translationKey);
      const hasModuleTranslation = Boolean(translation?.trim());

      // check for either module or base service translations
      return hasModuleTranslation || this.hasTranslationBase(translationKey);
  }

  getModuleKeyExists(translationKey: string): boolean {
      return !this._moduleTranslationsLoaded ? false : this._moduleTranslations[translationKey] != null;
  }

  async loadModuleTranslations(options?: ApiOptions) {
      this._moduleTranslationsLoading = true;
      const language = this._newSelectedLanguage ? this._newSelectedLanguage : this.selectedLanguage;
      // call service to receive translations for selected language
      await this.getModuleTranslationsFromService(language, options)
          .then((translations) => {
              this.setModuleTranslations(translations);
          })
          .catch(() => {
              this._moduleTranslationsLoaded = false;
          })
          .finally(() => {
              this._moduleTranslationsLoading = false;
          });
  }

  private setModuleTranslations(languageTranslations: LanguageTranslations | null): void {
      if (!languageTranslations) {
          this._moduleTranslationsLoaded = false;
          this.loggerService.log(`No ${TranslationLibraryName} translations!`, LogType.error);
          return;
      }

      this._moduleTranslations = languageTranslations.Translations;
      this._moduleTranslationsLoaded = true;
  }

  private async getModuleTranslationsFromService(languageCultureName: string, options?: ApiOptions): Promise<LanguageTranslations | null> {
    options.forceIncludeAuthenticationHeaders = true ;  
    const url = `${TranslationsUrl}Translations/${TranslationLibraryName}/${languageCultureName}`;
      return (await this.apiService.request<LanguageTranslations>(new HttpRequest('GET', url), options)).body;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getStringBase(translationKey: string, options?: IGetStringOptions): string {
      return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public hasTranslationBase(translationKey: string): boolean {
      return false;
  }

  ngOnDestroy(): void {
      this.moduleLocalizationChange.complete();
  }
 }
