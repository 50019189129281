import cloneDeep from 'lodash-es/cloneDeep';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { v4 as uuidv4 } from 'uuid';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { DocumentAccessMode, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';
import { DeckingSubstitution } from './../../entities/decking-substitution/decking-substitution';
import { SubstitutionAreaModel } from './../../entities/decking-substitution/substitution-area';
import { IndividualZoneModel, SubstitutionZoneModel } from './../../entities/decking-substitution/substitution-zone';
import { AreaSummaryModel } from './../../entities/decking-design/area-model';
import { DeckingReport } from './../../entities/decking-design/decking-report-info';
import { DeckingCodeListService } from './../../services/decking-code-list/decking-code-list.service';
import { ApiService } from './../../services/external/api.service';
import { DeckingDocumentService } from './../../services/decking-document/decking-document.service';
import { LocalizationService } from './../../services/external/localization.service';
import { BrowserService } from './../../services/external/browser.service';
import { DesignTemplateService } from './../../services/external/design-template.service';
import { DeckingSubstitutionDefaultFactoryService } from './factory/decking-substitution-default-factory.service';
import { DeckingSubstitutionAreasService } from './../../services/decking-areas/decking-substitution-areas.service';
import { DeckingSubstitutionZonesService } from './../../services/decking-zones/substitution-zones.service';
import { SubstitutionZoneInputsSettingsService } from './settings/zone-inputs/decking-substitution-inputs-settings.service';
import { environment } from './../../../environments/environmentDecking';
import { RelevantLoads } from './../../entities/decking-code-list/enums/relevant-loads';
import { SubstitutionSettings } from './../../entities/settings/substitution-settings';
import { BaseProjectService } from './base-project-service';
import { DeckType } from 'src/decking/entities/decking-code-list/enums/deck-type';
import { ThumbnailIconsBase64 } from 'src/decking/entities/enums/thumbnail-icons';
import { DeckingSubstitutionTrackingService } from '../decking-tracking/decking-substitution-tracking.service';

@Injectable({
    providedIn: 'root'
})
// Service in charge to keep the State of a Substitution entity using using RxJs BehaviorSubject
// Any modification, should be performed here using provided deckingSubstitutionSubject.
export class DeckingSubstitutionService extends BaseProjectService {
    responsePromise: Promise<HttpResponse<DeckingSubstitution>>;
    public readonly currentDeckingSubstitution$: Observable<DeckingSubstitution>;

    /**
     * @private Observable to watch for changes on current area.
     */
    public readonly currentArea$: Observable<SubstitutionAreaModel>;

    /**
     * @private Observable to watch for changes on current zone
     */
    public readonly currentZone$: Observable<SubstitutionZoneModel>;

    /**
     * private Observable to watch for change on the general data of the areas
     */
    public readonly currentAreasSummary$: Observable<AreaSummaryModel[]>;

    public readonly canDeleteZone$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for required shear stiffness input was changed
     */
    public readonly isRequiredShearStiffnessSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for required uplift submittal input was changed
     */
    public readonly isRequiredUpliftSubmittalSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for relevant load at zone level was changed
     */
    public readonly isRelevantLoadAtZoneLevelSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for substitution required shear stiffness input was changed
     */
    public readonly isSubstitutionRequiredShearStiffnessSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for substitution required uplift submittal input was changed
     */
    public readonly isSubstitutionRequiredUpliftSubmittalSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for substitution relevant load at zone level was changed
     */
    public readonly isSubstitutionRelevantLoadAtZoneLevelSetting$: Observable<boolean>;

    /**
     * @private Observable to watch for changes on the current settings
     */
    public readonly currentSettings$: Observable<SubstitutionSettings>;

    /**
     * @private Observable to watch for changes on the decking report data
     */
    public readonly currentReportData$: Observable<DeckingReport>;

    private currentSubstitution: DeckingSubstitution;
    private deckingSubstitutionSubject: BehaviorSubject<DeckingSubstitution>;

    private undoStack: Array<DeckingSubstitution> = [];

    private redoStack: Array<DeckingSubstitution> = [];

    isSubstitutionSettingUpdated: boolean;

    constructor(
        protected override deckingCodeListService: DeckingCodeListService,
        protected override apiService: ApiService,
        protected override documentService: DeckingDocumentService,
        protected override localizationService: LocalizationService,
        protected override browserService: BrowserService,
        protected override designTemplateService: DesignTemplateService,
        protected substitutionDefaultFactory: DeckingSubstitutionDefaultFactoryService,
        protected deckingSubstitutionAreasService: DeckingSubstitutionAreasService,
        protected deckingSubstitutionZonesService: DeckingSubstitutionZonesService,
        protected substitutionZoneInputsSettingsService: SubstitutionZoneInputsSettingsService,
        protected substitutionTrackingService: DeckingSubstitutionTrackingService
    ) {
        super(deckingCodeListService, apiService, documentService, localizationService, browserService, designTemplateService);
        this.deckingSubstitutionSubject = new BehaviorSubject({} as DeckingSubstitution);

        this.currentDeckingSubstitution$ = this.deckingSubstitutionSubject.asObservable();

        this.currentArea$ = this.currentDeckingSubstitution$.pipe(map((substitution: DeckingSubstitution) => {
            const areaIndex = substitution?.currentAreaIndex;
            const areas = substitution?.areas;
            if (!isNaN(areaIndex) && areas) {
                return areas[areaIndex];
            }

            return null;
        }), shareReplay(1));

        this.currentZone$ = this.currentDeckingSubstitution$.pipe(map((substitution: DeckingSubstitution) => {
            const areaIndex = substitution?.currentAreaIndex;
            const areas = substitution?.areas;
            const zoneIndex = substitution?.currentZoneIndex;
            if (!isNaN(areaIndex) && areas && !isNaN(zoneIndex)) {
                return areas[areaIndex]?.zones[zoneIndex];
            }
            return null;
        }), shareReplay(1));

        this.currentSettings$ = this.currentDeckingSubstitution$.pipe(map((substitution: DeckingSubstitution) => {
            return substitution.settings;
        }), shareReplay(1));

        this.currentReportData$ = this.currentDeckingSubstitution$.pipe(map((substitution: DeckingSubstitution) => {
            return substitution.report;
        }), shareReplay(1));

        this.currentAreasSummary$ = this.currentDeckingSubstitution$.pipe(map((substitution: DeckingSubstitution) => {
            return substitution.areas?.map(a => {
                return {
                    id: a.id,
                    name: a.name,
                    eTag: a.eTag,
                };
            });
        }), shareReplay(1));

        this.canDeleteZone$ = this.currentArea$.pipe(map(a => this.deckingSubstitutionZonesService.canDeleteZone(a)), shareReplay(1));
        this.isRequiredShearStiffnessSetting$ = this.currentSettings$.pipe(map(settings => settings.requiredShearStiffness.value), distinctUntilChanged());
        this.isRequiredUpliftSubmittalSetting$ = this.currentSettings$.pipe(map(settings => settings.requiredUpliftSubmittal.value), distinctUntilChanged());
        this.isRelevantLoadAtZoneLevelSetting$ = this.currentSettings$.pipe(map(settings => settings.windAndSeismicLoadsAtZoneLevel.value), distinctUntilChanged());

        this.isSubstitutionRequiredShearStiffnessSetting$ = this.currentSettings$.pipe(map(settings => settings.substitutionRequiredShearStiffness?.value), distinctUntilChanged());
        this.isSubstitutionRequiredUpliftSubmittalSetting$ = this.currentSettings$.pipe(map(settings => settings.substitutionRequiredUpliftSubmittal?.value), distinctUntilChanged());
        this.isSubstitutionRelevantLoadAtZoneLevelSetting$ = this.currentSettings$.pipe(map(settings => settings.substitutionWindAndSeismicLoadsAtZoneLevel?.value), distinctUntilChanged());
    }

    /**
     * Get last area selected.
     */
    public get currentArea(): SubstitutionAreaModel {
        return this.currentSubstitution.areas[this.currentSubstitution.currentAreaIndex];
    }

    /**
     * Get last zone selected.
     */
    public get currentZone(): SubstitutionZoneModel {
        return this.currentSubstitution.areas[this.currentSubstitution.currentAreaIndex]?.zones[this.currentSubstitution.currentZoneIndex];
    }

    /**
     * Get current substitution synchronously
     */
    public getCurrentSubstitution(): DeckingSubstitution {
        return this.currentSubstitution;
    }

    public updateToExistingSubstitution() {
        this.currentSubstitution.isNew = false;
    }

    public async loadDeckingSubstitution(deckingSubstitutionId: string, documentId: string): Promise<DeckingSubstitution> {
        this.initializeDocument();
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        // Get Decking Substitution from decking-substitution-svc
        const deckingSubstitution = await this.getDeckingSubstitutionById(deckingSubstitutionId);

        // get document data
        this._document.next(null);
        if (!deckingSubstitution.isTemplate) {
            this._document.next(this.documentService.findDesignById(documentId));
        }

        // Set saved property to true to avoid calculation
        deckingSubstitution.saved = true;

        this.documentId = documentId;
        this.setSubstitution(deckingSubstitution, true);
        this.resetUndoRedoStacks();
        this.updateEnvironmentBasedInCurrentSubstitution();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();
        
        await this.substitutionTrackingService.trackSubstitutionOpened(deckingSubstitution);

        return deckingSubstitution;
    }

    public async createNewSubstitution(projectId: string, customName: string = null): Promise<DeckingSubstitution> {
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();
        // Create New Substitution in the state
        const deckingSubstitution = this.substitutionDefaultFactory.buildDefaultDeckingSubstitution(projectId, customName);

        return await this.loadNewSubstitution(projectId, deckingSubstitution);
    }

    public async createNewSubstitutionFromTemplate(projectId: string, substitutionFromTemplate: DeckingSubstitution): Promise<DeckingSubstitution> {
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        return await this.loadNewSubstitution(projectId, substitutionFromTemplate);
    }

    private async loadNewSubstitution(projectId: string, deckingProject: DeckingSubstitution): Promise<DeckingSubstitution> {
        this._document.next(await this.documentService.addDesign(projectId, null, true, false, true, deckingProject));
        this.documentId = this.document.id;
        deckingProject.documentId = this.document.id;

        // Passing Substitution as NULL, because we are going to work only with DeckingSubstitution.
        this.setSubstitution(deckingProject);
        this.updateEnvironmentBasedInCurrentSubstitution();
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        await this.substitutionTrackingService.trackSubstitutionOpened(deckingProject);

        return deckingProject;
    }

    public async createNewSubstitutionWithSettings(projectId: string, substitutionSettings: SubstitutionSettings, customName: string = null): Promise<DeckingSubstitution> {
        await this.initDeckingDesignCodeList();
        // Set region settings for new substitution
        const settings = cloneDeep(substitutionSettings);
        // Create New Substitution in the state
        const deckingSubstitution = this.substitutionDefaultFactory.buildDeckingSubstitutionFromSettings(projectId, settings, customName);

        this._document.next(await this.documentService.addDesign(projectId, null, true, false, true, deckingSubstitution));
        this.documentId = this.document.id;
        deckingSubstitution.documentId = this.document.id;
        this.setSubstitution(deckingSubstitution);
        this.updateEnvironmentBasedInCurrentSubstitution();
        this.resetUndoRedoStacks();
        this.updateSubstitutionSettings(substitutionSettings, projectId, customName);

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        await this.substitutionTrackingService.trackSubstitutionOpened(deckingSubstitution);

        return deckingSubstitution;
    }

    public async createNewSubstitutionFromExternalFile(projectId: string, deckingSubstitution: DeckingSubstitution, comesFromPEFile = false, isNewWindowImport = false): Promise<DeckingSubstitution> {
        deckingSubstitution = this.updateDefaultSetting(deckingSubstitution);
        await this.prepareDeckingSubstitution(deckingSubstitution, projectId, comesFromPEFile);

        if (comesFromPEFile) {
            deckingSubstitution.saved = false;
            deckingSubstitution.id = uuidv4();
        }
        // Store Substitution in DocumentService
        // Passing Substitution as NULL, because we are going to work only with DeckingSubstitution.
        const newDocument = await this.documentService.addDesign(projectId, null, true, false, true, deckingSubstitution);
        deckingSubstitution.documentId = newDocument.id;
        // It saves the substitution and returns when is a new window import.
        if (isNewWindowImport) {
            await this.saveSubstitution(deckingSubstitution);
            return deckingSubstitution;
        }

        this._document.next(newDocument);
        this.documentId = this.document.id;
        this.setSubstitution(deckingSubstitution);
        this.updateEnvironmentBasedInCurrentSubstitution();
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        await this.substitutionTrackingService.trackSubstitutionOpened(deckingSubstitution);

        return deckingSubstitution;
    }

    public async replaceExistingSubstitution(oldSubstitution: IDesignListItem, deckingSubstitution: DeckingSubstitution, isNewWindowImport = false): Promise<DeckingSubstitution> {
        deckingSubstitution = this.updateDefaultSetting(deckingSubstitution);
        await this.prepareDeckingSubstitution(deckingSubstitution, oldSubstitution.projectId, true);

        // Store Substitution in DocumentService
        // Passing Substitution as NULL, because we are going to work only with DeckingSubstitution.
        deckingSubstitution.saved = false;
        deckingSubstitution.id = await this.getDeckingIdFromDocumentId(oldSubstitution.id);
        deckingSubstitution.documentId = oldSubstitution.id;
        const oldDeckingSubstitution = await this.getDeckingSubstitutionById(deckingSubstitution.id);
        deckingSubstitution.eTag = oldDeckingSubstitution.eTag;

        // It saves the substitution and returns when is a new window import.
        if (isNewWindowImport) {
            await this.saveSubstitution(deckingSubstitution);
            return deckingSubstitution;
        }

        this._document.next(oldSubstitution);
        this.documentId = oldSubstitution.id;
        this.setSubstitution(deckingSubstitution);
        this.updateEnvironmentBasedInCurrentSubstitution();
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        await this.substitutionTrackingService.trackSubstitutionOpened(deckingSubstitution);

        return deckingSubstitution;
    }

    public updateDefaultSetting(substitution: DeckingSubstitution): DeckingSubstitution {
        if(substitution.settings.designStandard === undefined || substitution.settings.designStandard === null) {
            substitution.settings.designStandard = {id: 1,  value: 'AISI S310-20', index: 2};
        }
        this.updatesubstitutionSettingsInArea(substitution);
        return substitution;
    }

    public updatesubstitutionSettingsInArea(substitution: DeckingSubstitution) {
        substitution.areas.forEach(area => {
            if(area.definitionOfSidelapConnectors === undefined || area.definitionOfSidelapConnectors === null) {
                area.definitionOfSidelapConnectors = { id: substitution.settings.definitionOfSidelapConnectors.id, 
                    value: substitution.settings.definitionOfSidelapConnectors.value, 
                    index: substitution.settings.definitionOfSidelapConnectors.index
                };
            }
            if(area.sidelapsSpacingSettings === undefined || area.sidelapsSpacingSettings === null) {
                area.sidelapsSpacingSettings = substitution.settings.sidelapsSpacingSettings;
            }
            if(area.sidelapsNumberSettings === undefined || area.sidelapsNumberSettings === null) {
                area.sidelapsNumberSettings = substitution.settings.sidelapsNumberSettings;
            }
          });
    }

    private async saveSubstitution(deckingSubstitution: DeckingSubstitution) {
        deckingSubstitution.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.zoneSpecified.result.numberOfEdgeSupportConnections) {
                    zone.zoneSpecified.result.numberOfEdgeSupportConnections = {value: 0};
                }
                if(!zone.zoneSubstituted.result.numberOfEdgeSupportConnections) {
                    zone.zoneSubstituted.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        const urlSave = `${environment.deckingDesignServiceUrl}api/SubstitutionCommand/save`;
        await this.apiService.request<DeckingSubstitution>(new HttpRequest('POST', urlSave, deckingSubstitution));
    }

    private async prepareDeckingSubstitution(deckingSubstitution: DeckingSubstitution, projectId: string, comesFromPEFile = false) {
        this.initializeDocument();
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        if (!comesFromPEFile) {
            // Creating a Settings object with default values, except for design Method,
            // Relevant Load and Sidelap configuration, since they are configured in ProfisDF
            const designMethod = deckingSubstitution.settings.designMethod;
            const designStandard = deckingSubstitution.settings.designStandard;
            const relevantLoads = deckingSubstitution.settings.relevantLoads;
            const definitionOfSidelapConnectors = deckingSubstitution.settings.definitionOfSidelapConnectors;
            const sidelapsSpacingSettings = deckingSubstitution.settings.sidelapsSpacingSettings;
            const sidelapsNumberSettings = deckingSubstitution.settings.sidelapsNumberSettings;
            const defaultDeckingSubstitution = this.substitutionDefaultFactory.buildDefaultDeckingSubstitution(projectId);
            deckingSubstitution.settings = defaultDeckingSubstitution.settings;
            deckingSubstitution.settings.designMethod = designMethod;
            deckingSubstitution.settings.designStandard = designStandard;
            deckingSubstitution.settings.relevantLoads = relevantLoads;
            deckingSubstitution.settings.definitionOfSidelapConnectors = definitionOfSidelapConnectors;
            if (sidelapsSpacingSettings) {
                deckingSubstitution.settings.sidelapsSpacingSettings = sidelapsSpacingSettings;
            }
            if (sidelapsNumberSettings) {
                deckingSubstitution.settings.sidelapsNumberSettings = sidelapsNumberSettings;
            }
            if (!deckingSubstitution.name) {
                deckingSubstitution.name = this.substitutionDefaultFactory.createDeckingDesignName(projectId, true);
            }
            deckingSubstitution.report = defaultDeckingSubstitution.report;
        }
    }

    public async getDeckingIdFromDocumentId(documentId: string, isLock = true): Promise<string> {
        const deckingContent = await this.documentService.getDeckingDesignContent(documentId, this.documentService.openNewSessionForDesign(documentId), documentId, isLock);
        const deckingDesign = JSON.parse(this.browserService.fromBase64(deckingContent.body.filecontent)) as DeckingSubstitution;
        return deckingDesign.id;
    }

    private initializeDocument(): void {
        if (!this._document) {
            this._document = new BehaviorSubject({} as IDesignListItem);
        }
    }

    public async copySubstitution(deckingSubstitutionId: string, substitutionName: string, projectId: string): Promise<{ id: string; substitutionId: string }> {
        const newDeckingSubstitution: DeckingSubstitution = await this.copyDeckingSubstitution(deckingSubstitutionId, substitutionName);
        newDeckingSubstitution.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.zoneSpecified.result.numberOfEdgeSupportConnections) {
                    zone.zoneSpecified.result.numberOfEdgeSupportConnections = {value: 0};
                }
                if(!zone.zoneSubstituted.result.numberOfEdgeSupportConnections) {
                    zone.zoneSubstituted.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        // Store Substitution in DocumentService, Passing Substitution as NULL, because we are going to work only with DeckingSubstitution.
        const document = await this.documentService.addDesign(projectId, null, true, false, true, newDeckingSubstitution);
        newDeckingSubstitution.documentId = document.id;
        await this.updateDocumentDesignContent(document, true, false, DocumentAccessMode.Open, newDeckingSubstitution);
        const urlSave = `${environment.deckingDesignServiceUrl}api/SubstitutionCommand/save`;
        await this.apiService.request<DeckingSubstitution>(new HttpRequest('POST', urlSave, newDeckingSubstitution));
        return {
            id: document.id,
            substitutionId: newDeckingSubstitution.id
        };
    }

    public async copyDeckingSubstitution(deckingSubstitutionId: string, substitutionName: string): Promise<DeckingSubstitution> {
        const url = `${environment.deckingDesignServiceUrl}api/SubstitutionCommand/copy`;
        const rData = { id: deckingSubstitutionId, name: substitutionName };
        return (await this.apiService.request<DeckingSubstitution>(new HttpRequest('POST', url, rData))).body;
    }

    public async createDesignTemplate(deckingDesignId: string, isTemplate = false, substitutionName?: string, projectId?: string): Promise<DeckingSubstitution> {
        if (!substitutionName) {
            substitutionName = this.substitutionDefaultFactory.createDeckingDesignName(projectId, true);
        }
        const url = `${environment.deckingDesignServiceUrl}api/DesignTemplateCommand`;
        const rData = { id: deckingDesignId, name: substitutionName, isTemplate: isTemplate };
        return (await this.apiService.request<DeckingSubstitution>(new HttpRequest('POST', url, rData))).body;
    }

    public async deleteSubstitution(deckingSubstitutionId: string): Promise<void> {
        const url = `${environment.deckingDesignServiceUrl}api/SubstitutionCommand/${deckingSubstitutionId}`;
        await this.apiService.request(new HttpRequest('DELETE', url));
    }

    public setSubstitution(deckingSubstitutionDesign: DeckingSubstitution, lockDocument = false): void {
        this.currentSubstitution = deckingSubstitutionDesign;
        this.emitSubstitutionChanged();
        // The document has been loaded from DocumentServices, so we need to update the content.
        if (this.document) {
            lockDocument ? this.updateDocumentDesignContent(this.document, false, true, DocumentAccessMode.Open)
            : this.updateDocumentDesignContent(this.document);
        }       
    }

    public addNewArea(newArea: SubstitutionAreaModel): void {
        if (this.currentSubstitution.areas.length >= this.maximumNumberAreas) {
            return;
        }
        newArea.name.value = this.checkDuplicateAreaOrZoneName(newArea.name.value);
        this.pushUndoStack(true);
        if (this.currentSubstitution.areas === undefined) {
            this.currentSubstitution.areas = [];
        }
        this.currentSubstitution.areas.push(newArea);
        this.currentSubstitution.saved = false;
        this.setCurrentArea(this.currentSubstitution.areas.length - 1);
        this.substitutionTrackingService.addArea();
        this.substitutionTrackingService.addZone(newArea.zones.length);
    }

    public deleteArea(index: number): void {
        this.substitutionTrackingService.removeArea();
        this.substitutionTrackingService.removeZone(this.currentSubstitution.areas[index].zones.length);
        this.pushUndoStack(true);
        this.areasZonesNames.delete(this.currentSubstitution.areas[index].name.value);
        this.currentSubstitution.areas.splice(index, 1);
        this.currentSubstitution.saved = false;
        this.setCurrentArea(this.currentSubstitution.areas.length - 1);
    }

    public duplicateArea(index: number): void {
        const area = this.currentSubstitution.areas[index];
        const areaCloned: SubstitutionAreaModel = Object.assign(new SubstitutionAreaModel(), JSON.parse(JSON.stringify(area)));
        areaCloned.name = { value: format(this.getCopiedAreaPrefixName(), areaCloned.name.value) };
        areaCloned.id = uuidv4();
        this.addNewArea(areaCloned);
    }

    public updateAreas(areasUpdated: SubstitutionAreaModel[], newIndex: number | null = null): void {
        this.pushUndoStack(true);
        this.currentSubstitution.areas = areasUpdated;
        this.currentSubstitution.saved = false;
        if (newIndex !== null && newIndex > -1) {
            this.setCurrentArea(newIndex);
        }
        this.emitSubstitutionChanged();
    }

    public updateCurrentArea(area: SubstitutionAreaModel, isDirty = true): void {
        if (!this.deckingSubstitutionAreasService.areInputsEquals(this.currentArea, area)) {
            area.isDirty = isDirty;
            this.areasZonesNames.delete(this.currentArea.name.value);
            area.name.value = this.checkDuplicateAreaOrZoneName(area.name.value);
            this.pushUndoStack(true);
            this.currentSubstitution.areas[this.currentSubstitution.currentAreaIndex] = { ...area };
            this.currentSubstitution.saved = false;
            this.emitSubstitutionChanged();
        }
    }

    public checkDuplicateAreaOrZoneName(areaName: string, zoneName = ''): string {
        if (zoneName === '') {
            return this.checkDuplicateAreaName(areaName);
        }
        else {
            return this.checkDuplicateZoneName(areaName, zoneName);
        }
    }

    private initAreasZoneNames(): void {
        if (this.areasZonesNames) {
            this.areasZonesNames.clear();
        }
        this.areasZonesNames = new Map<string, Set<string>>();
        this.areasZonesNames.set(this.currentArea.name.value, new Set<string>());
        this.currentSubstitution.areas.forEach((area) =>
            this.areasZonesNames.set(
                area.name.value,
                new Set(area.zones.map((zone) => zone.name.value))
            )
        );
    }

    private checkDuplicateAreaName(areaName: string): string {
        if (areaName == '') {
            areaName = this.substitutionDefaultFactory.getDefaultArea(this.currentSubstitution.settings, this.currentSubstitution.areas.length).name.value;
        }
        if (!this.areasZonesNames.has(areaName)) {
            this.areasZonesNames.set(areaName, new Set<string>());
        } else {
            while (this.areasZonesNames.has(areaName)) {
                areaName = `${areaName} ${this.DUPLICATE_AREA_ZONE_NAME_SUFFIX}`;
            }
            this.areasZonesNames.set(areaName, new Set<string>());
        }
        this.currentArea.zones.forEach(z => this.areasZonesNames.get(areaName).add(z.name.value));
        return areaName;
    }

    private checkDuplicateZoneName(areaName: string, zoneName: string): string {
        if (!this.areasZonesNames.has(areaName)) {
            this.initAreasZoneNames();
        }
        if (this.areasZonesNames.has(areaName) && !this.areasZonesNames.get(areaName).has(zoneName)) {
            this.areasZonesNames.get(areaName).add(zoneName);
        } else {
            while (this.areasZonesNames.has(areaName) && this.areasZonesNames.get(areaName).has(zoneName)) {
                zoneName = `${zoneName} ${this.DUPLICATE_AREA_ZONE_NAME_SUFFIX}`;
            }
            this.areasZonesNames.get(areaName).add(zoneName);
        }
        return zoneName;
    }

    public addNewZoneToCurrentArea(): void {
        this.pushUndoStack(true);
        const defaultZone = this.deckingSubstitutionZonesService.getDefaultZone(this.currentSubstitution.settings);
        defaultZone.name.value = this.checkDuplicateAreaOrZoneName(this.currentArea.name.value, defaultZone.name.value);
        this.deckingSubstitutionAreasService.addZone(this.currentArea, defaultZone);
        this.updateZones(this.currentArea.zones);
        this.substitutionTrackingService.addZone();
    }

    public updateZones(zonesUpdated: SubstitutionZoneModel[]): void {
        this.areasZonesNames.get(this.currentArea.name.value).clear();
        zonesUpdated.forEach(z => {
            this.areasZonesNames.get(this.currentArea.name.value).add(z.name.value);
        });
        this.currentArea.zones = zonesUpdated;
        this.currentSubstitution.saved = false;
        this.emitSubstitutionChanged();
    }

    public toggleLockZone(zone: SubstitutionZoneModel, index: number): void {
        zone.isLocked.value = !zone.isLocked?.value;
        this.updateZone(zone, index);
    }

    public deleteZone(zoneIndex: number): void {
        this.areasZonesNames.get(this.currentArea.name.value).delete(this.currentArea.zones[zoneIndex].name.value);
        this.pushUndoStack(true);
        this.deckingSubstitutionAreasService.deleteZone(this.currentArea, zoneIndex);
        if (this.currentSubstitution.currentZoneIndex === zoneIndex) {
            this.setCurrentZone(0);
        }
        this.updateZoneIndexOnDelete(zoneIndex);
        this.updateZones(this.currentArea.zones);
        this.substitutionTrackingService.removeZone();
    }

    public updateCurrentZone(zoneUpdated: SubstitutionZoneModel): void {
        this.updateZone(zoneUpdated, this.currentSubstitution.currentZoneIndex);
    }

    public updateZone(zoneUpdated: SubstitutionZoneModel, index: number, isDirty = true, isFastenerEstimationUpdate = false): void {
        if (!this.deckingSubstitutionZonesService.areZoneInputsEqual( // only update is a change was made
            this.currentArea.zones[index],
            zoneUpdated
        )) {
            zoneUpdated.zoneSpecified.isDirty = isDirty;
            zoneUpdated.zoneSubstituted.isDirty = isDirty;
            zoneUpdated.isFastenerEstimationUpdate = isFastenerEstimationUpdate;
            this.areasZonesNames.get(this.currentArea.name.value).delete(this.currentArea.zones[index].name.value);
            zoneUpdated.name.value = this.checkDuplicateAreaOrZoneName(this.currentArea.name.value, zoneUpdated.name.value);
            this.pushUndoStack(true);
            this.currentArea.zones[index] = zoneUpdated;
            this.currentSubstitution.saved = false;
            this.emitSubstitutionChanged();
        }
    }

    // TUDU: fix missing id in zone model in backend
    public getZoneCurrentIndex(zone: SubstitutionZoneModel): number {
        return this.currentArea.zones.findIndex(x => x.id === zone.id);
    }

    public resetAllAreaZones(
        zonePropertiesToReset: Array<keyof IndividualZoneModel> =
            ['deckGauge', 'pattern', 'frameFastener', 'sidelapConnector', 'side', 'result']
    ) {
        const areasCloned = cloneDeep(this.currentSubstitution.areas);
        for (const area of areasCloned) {
            this.resetZonePropertiesOfArea(area, zonePropertiesToReset);
        }
        return areasCloned;
    }

    public resetZonePropertiesOfArea(
        area: SubstitutionAreaModel,
        zonePropertiesToReset: Array<keyof IndividualZoneModel> =
            ['deckGauge', 'pattern', 'frameFastener', 'sidelapConnector', 'side', 'result']
    ) {
        const defaultZone = this.deckingSubstitutionZonesService.getDefaultZone(this.currentSubstitution.settings);
        for (const zone of area.zones) {
            zone.zoneSpecified = this.resetIndividualZone(zone.zoneSpecified, defaultZone, zonePropertiesToReset);
            zone.alternatives = [];
            zone.zoneSubstituted = this.resetIndividualZone(zone.zoneSubstituted, defaultZone, zonePropertiesToReset);
        }
        return area;
    }

    private resetIndividualZone(
        zone: IndividualZoneModel,
        defaultZone: SubstitutionZoneModel,
        zonePropertiesToReset: Array<keyof IndividualZoneModel> =
            ['deckGauge', 'pattern', 'frameFastener', 'sidelapConnector', 'side', 'result']): IndividualZoneModel {
        zone.deckGauge = zonePropertiesToReset.includes('deckGauge') ? defaultZone.zoneSpecified.deckGauge : zone.deckGauge;
        zone.pattern = zonePropertiesToReset.includes('pattern') ? defaultZone.zoneSpecified.pattern : zone.pattern;
        zone.frameFastener = zonePropertiesToReset.includes('frameFastener') ? defaultZone.zoneSpecified.frameFastener : zone.frameFastener;
        zone.sidelapConnector = zonePropertiesToReset.includes('sidelapConnector') ? defaultZone.zoneSpecified.sidelapConnector : zone.sidelapConnector;
        zone.side = zonePropertiesToReset.includes('side') ? defaultZone.zoneSpecified.side : zone.side;
        zone.result = zonePropertiesToReset.includes('result') ? defaultZone.zoneSpecified.result : zone.result;
        return zone;
    }

    public updatePanel(area: SubstitutionAreaModel): void {
        const defaultFrameFastener = this.deckingCodeListService.GetDefaultFrameFastenerDropdownItem();
        const defaultSidelap = this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem();
        const defaultPattern = this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem();
        area.zones.forEach(zone => {
            zone.zoneSpecified.pattern = defaultPattern;
            zone.zoneSubstituted.pattern = defaultPattern;
            // Change frame fastener if deck panel is restricted
            if (zone.zoneSpecified.frameFastener && this.deckingCodeListService.GetFrameFastenerItem(zone.zoneSpecified.frameFastener.id).restrictedPanels.includes(area.deckPanel.id)) {
                zone.zoneSpecified.frameFastener = defaultFrameFastener;
            }
            if (zone.zoneSubstituted.frameFastener && this.deckingCodeListService.GetFrameFastenerItem(zone.zoneSubstituted.frameFastener.id).restrictedPanels.includes(area.deckPanel.id)) {
                zone.zoneSubstituted.frameFastener = defaultFrameFastener;
            }
            // Change sidelap connector if panel is restricted
            if (zone.zoneSpecified.sidelapConnector && this.deckingCodeListService.GetSidelapItem(zone.zoneSpecified.sidelapConnector.id).restrictedPanels.includes(area.deckPanel.id)) {
                zone.zoneSpecified.sidelapConnector = defaultSidelap;
            }
            if (zone.zoneSubstituted.sidelapConnector && this.deckingCodeListService.GetSidelapItem(zone.zoneSubstituted.sidelapConnector.id).restrictedPanels.includes(area.deckPanel.id)) {
                zone.zoneSubstituted.sidelapConnector = defaultSidelap;
            }
        });
        area.panelWidth = this.deckingCodeListService.GetDefaultPanelWidthDropdownItem(area.deckPanel.id);
        area.panelType = this.deckingCodeListService.GetDefaultPanelTypeDropdownItem(area.deckPanel.id);
        this.updateCurrentArea(area);
    }

    public updatePanelType(area: SubstitutionAreaModel): void {
        const defaultSidelap = this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem();
        area.zones.forEach(zone => {
            // Change sidelap connector if it doesn't apply to panel type
            if (zone.zoneSpecified.sidelapConnector && !this.deckingCodeListService.GetSidelapItem(zone.zoneSpecified.sidelapConnector.id).panelTypes.includes(area.panelType.id)) {
                zone.zoneSpecified.sidelapConnector = defaultSidelap;
            }
            if (zone.zoneSubstituted.sidelapConnector && !this.deckingCodeListService.GetSidelapItem(zone.zoneSubstituted.sidelapConnector.id).panelTypes.includes(area.panelType.id)) {
                zone.zoneSubstituted.sidelapConnector = defaultSidelap;
            }
        });
        this.updateCurrentArea(area);
    }

    public updatePanelWidth(area: SubstitutionAreaModel): void {
        area.zones.forEach(zone => {
            const defaultPattern = this.deckingCodeListService.GetDefaultPatternDropdownItem();
            zone.zoneSpecified.pattern = defaultPattern;
            zone.zoneSubstituted.pattern = defaultPattern;
            zone.alternatives = [];
        });
        this.updateCurrentArea(area);
    }

    public updateZoneTypeForZones(relevantLoads: RelevantLoads): void {
        this.currentArea.zones.forEach(zone => {
            zone.relevantLoads = { value: relevantLoads };
            zone.zoneSpecified.isDirty = true;
            zone.zoneSubstituted.isDirty = true;
        });
        this.updateZones(this.currentArea.zones);
    }

    public setCurrentArea(index: number): void {
        if (index >= 0 && index < this.currentSubstitution.areas.length) {
            this.currentSubstitution.currentAreaIndex = index;
            this.currentSubstitution.currentZoneIndex = 0;
            this.currentSubstitution.saved = false;
            this.emitSubstitutionChanged();
        }
    }

    public setCurrentZone(index: number): void {
        if (index !== this.currentSubstitution.currentZoneIndex) {
            this.currentSubstitution.currentZoneIndex = index;
            this.currentSubstitution.saved = false;
            this.emitSubstitutionChanged();
        }
    }

    public updateZoneIndexOnDelete(indexDeleted: number): void {
        if (this.currentSubstitution.currentZoneIndex > indexDeleted) {
            this.currentSubstitution.currentZoneIndex--;
            this.emitSubstitutionChanged();
        }
    }

    public isAreaSelected(index: number): boolean {
        return this.currentSubstitution.currentAreaIndex === index;
    }

    public isZoneSelected(index: number): boolean {
        return this.currentSubstitution.currentZoneIndex === index;
    }

    public getCurrentAreaIndex(): number {
        return this.currentSubstitution.currentAreaIndex;
    }

    public pushUndoStack(clearRedo = false): void {
        if (this.currentSubstitution) {
            const cloneSubstitution = cloneDeep(this.currentSubstitution);
            this.undoStack.push(cloneSubstitution);
            if (this.undoStack.length > this.Max_UndoRedo_Stack_Size) {
                this.undoStack.shift();
            }
            if (this.redoStack.length > 0 && clearRedo) {
                this.redoStack = [];
            }
            this.emitUndoRedoState();
        }
    }

    public undoChange(): void {
        if (this.undoStack.length > 0) {
            this.redoStack.push(cloneDeep(this.currentSubstitution));
            this.propagateMementoSubstitution(this.undoStack.pop());
        }
    }

    public redoChange(): void {
        if (this.redoStack.length > 0) {
            this.pushUndoStack();
            this.propagateMementoSubstitution(this.redoStack.pop());
        }
    }

    public async updateSubstitutionSettings(substitutionSettings: SubstitutionSettings, projectId: string, substitutionName: string, isDirty = true, isRelevantLoadsDifferent = false): Promise<void> {
        if (this.currentSubstitution.settings.definitionOfSidelapConnectors.index !== substitutionSettings.definitionOfSidelapConnectors.index) {
            this.currentSubstitution.areas = this.resetAllAreaZones(['result', 'side']);
        }

        if (this.document /*Not Template*/) {
            const currentDocument = this.document ?? this._document.value;
            currentDocument.designName = substitutionName ?? this.currentSubstitution.name;
            this._document.next(currentDocument);
        }

        this.updateDocumentDesignContent(this.document);
        this.fixPatternsValues(this.currentSubstitution.settings.length.id, substitutionSettings.length.id);
        this.currentSubstitution.settings = substitutionSettings;
        this.currentSubstitution.eTag = this.deckingSubstitutionSubject.getValue().eTag;
        this.currentSubstitution.saved = false;
        this.currentSubstitution.isDirty = isDirty;
        this.isSubstitutionSettingUpdated = true;
        this.currentSubstitution.areas.forEach(area => {
            area.definitionOfSidelapConnectors = { id: substitutionSettings.definitionOfSidelapConnectors.id, 
                value: substitutionSettings.definitionOfSidelapConnectors.value, 
                index: substitutionSettings.definitionOfSidelapConnectors.index
            };
            area.sidelapsSpacingSettings = substitutionSettings.sidelapsSpacingSettings;
            area.sidelapsNumberSettings = substitutionSettings.sidelapsNumberSettings;
            area.isDirty = true;
          });
        this.substitutionZoneInputsSettingsService.checkDesignZoneInputSettings(this.currentSubstitution, isRelevantLoadsDifferent);
        this.emitSubstitutionChanged();
    }

    public async updateDesignTemplateSettings(substitutionSettings: SubstitutionSettings, templateName: string): Promise<void> {
        this.currentSubstitution.settings = substitutionSettings;
        const urlSave = `${environment.deckingDesignServiceUrl}api/SubstitutionCommand/save`;
        await this.apiService.request<DeckingSubstitution>(new HttpRequest('POST', urlSave, this.currentSubstitution));
        const template = await this.designTemplateService.getById(this.currentSubstitution.documentId);
        template.DesignTemplateName = templateName;
        const updatedTemplate: IDesignTemplateDocument = {
            designStandardId: template.DesignStandardId,
            designTypeId: template.DesignTypeId,
            projectDesign: template.ProjectDesign,
            regionId: template.RegionId,
            anchorName: template.AnchorName,
            approvalNumber: template.ApprovalNumber,
            designTemplateDocumentId: template.DesignTemplateDocumentId,
            templateName: template.DesignTemplateName,
            thumbnailImageContent: ''
        };
        await this.designTemplateService.update(updatedTemplate);
    }

    public async getDeckingSubstitutionById(deckingSubstitutionId: string): Promise<DeckingSubstitution> {
        const url = `${environment.deckingDesignServiceUrl}api/SubstitutionQuery/${deckingSubstitutionId}`;
        this.responsePromise = this.apiService.request<DeckingSubstitution>(new HttpRequest('GET', url));
        return (await this.responsePromise).body;
    }

    public async reloadSubstitution(deckingSubstitution: DeckingSubstitution, deckingDocument: IDesignListItem): Promise<void> {
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        // Set saved property to true to avoid calculation
        deckingSubstitution.saved = true;

        // Associate Document to Decking Substitution
        this._document.next(deckingDocument);
        this.documentId = deckingDocument.id;

        this.setSubstitution(deckingSubstitution, true);
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        await this.substitutionTrackingService.trackSubstitutionOpened(deckingSubstitution);
    }

    public async updateDocumentDesignContent(document: IDesignListItem, unlock = false, exclusiveLock = false, documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open, deckingSubstitution: DeckingSubstitution = null): Promise<void> {
        const base64XmlContent = this.browserService.toBase64(deckingSubstitution ?? this.currentSubstitution);
        await this.documentService.updateDocumentDesignContent(document, base64XmlContent, unlock, exclusiveLock, documentAccessMode);
        const isSteelRoof = this.currentSubstitution.areas.some(x => x.deckType.id === DeckType.SteelroofDeck);
        if (isSteelRoof) {
            await this.documentService.updateDesignThumbnailImage(document.id, 'data:image/png;base64,' + ThumbnailIconsBase64.SteelroofBase64, false);
        } else {
            await this.documentService.updateDesignThumbnailImage(document.id, 'data:image/png;base64,' + ThumbnailIconsBase64.ConcreteBase64, false);
        }
    }

    private fixPatternsValues(oldLengthId: number, newLengthId: number): void {
        const wasLengthImperial = oldLengthId === Unit.ft || oldLengthId === Unit.inch || oldLengthId === Unit.mi;
        const isLengthImperial = newLengthId === Unit.ft || newLengthId === Unit.inch || newLengthId === Unit.mi;

        if (wasLengthImperial == isLengthImperial) {
            return;
        }

        this.currentSubstitution.areas.forEach(area => {
            const patterns = this.deckingCodeListService.GetPatternsDropdownItems(area.deckPanel.id, area.panelWidth.value, isLengthImperial);
            area.zones.forEach(zone => {
                if (zone.zoneSpecified.pattern) {
                    zone.zoneSpecified.pattern.value = patterns.filter(pattern => pattern.value && pattern.value.id == zone.zoneSpecified.pattern.id)[0].text;
                }
                if (zone.zoneSubstituted.pattern) {
                    zone.zoneSubstituted.pattern.value = patterns.filter(pattern => pattern.value && pattern.value.id == zone.zoneSubstituted.pattern.id)[0].text;
                }
            });
        });
    }

    private updateEnvironmentBasedInCurrentSubstitution(): void {
        this.setCurrentArea(this.currentSubstitution.currentAreaIndex);
        this.setCurrentZone(this.currentSubstitution.currentZoneIndex);
    }

    private emitSubstitutionChanged(): void {
        if (this.currentSubstitution.saved) {
            this.substitutionTrackingService.trackSubstitutionActivity(this.currentSubstitution);
        }
        this.deckingSubstitutionSubject.next(cloneDeep(this.currentSubstitution));  // clone to avoid exposing the object directly
    }

    private emitUndoRedoState(): void {
        this.canRedo.next(this.redoStack.length > 0);
        this.canUndo.next(this.undoStack.length > 0);
    }

    private resetUndoRedoStacks(): void {
        this.redoStack = [];
        this.undoStack = [];
        this.emitUndoRedoState();
    }

    /**
     * # Propagates a substitution as the current substitution.
     *
     * Takes a mementoSubstitution from the Undo/Redo stack and propagates it as the current
     * substitution, setting the etag and saved properties to allow the substitution to be saved.
     *
     * @param {DeckingSubstitution} mementoSubstitution  A saved substitution that comes from the undo/redo stack.
     */
    private propagateMementoSubstitution(mementoSubstitution: DeckingSubstitution) {
        this.currentSubstitution = mementoSubstitution;
        this.currentSubstitution.eTag = this.deckingSubstitutionSubject.getValue().eTag;
        this.currentSubstitution.saved = false;
        this.updateEnvironmentBasedInCurrentSubstitution();
        this.emitSubstitutionChanged();
        this.emitUndoRedoState();
    }

    private getCopiedAreaPrefixName(): string {
        return this.localizationService.getString('Agito.Hilti.Profis3.Decking.AreaManagement.CopiedAreaPrefixName');
    }

    public async dispose(): Promise<void> {
        this.documentId = null;
        await this.substitutionTrackingService.trackSubstitutionActivity(this.currentSubstitution);
        await this.substitutionTrackingService.trackSubstitutionClosed(this.currentSubstitution);
        // Unlocking the substitution.
        this.updateDocumentDesignContent(this.document, true);
    }
}
