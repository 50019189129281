import { DeckingZoneInputsSettingsService } from './settings/zone-inputs/decking-zone-inputs-settings.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { DeckingZonesService } from '../decking-zones/decking-zones.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeckingCodeListService } from '../decking-code-list/decking-code-list.service';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { DeckingDefaultFactoryService } from './factory/decking-default-factory.service';
import { DeckingDesignAreasService } from '../decking-areas/decking-design-areas.service';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { DesignSettings } from '../../entities/settings/design-settings';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DeckingTrackingService } from '../decking-tracking/decking-tracking.service';
import { v4 as uuidv4 } from 'uuid';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { DocumentAccessMode, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';
import { DeckingDesign } from '../../entities/decking-design/decking-design';
import { AreaModel, AreaSummaryModel } from '../../entities/decking-design/area-model';
import { ZoneModel } from '../../entities/decking-design/zone-model';
import { DeckingReport } from '../../entities/decking-design/decking-report-info';
import { ApiService } from '../external/api.service';
import { DeckingDocumentService } from '../decking-document/decking-document.service';
import { LocalizationService } from '../external/localization.service';
import { BrowserService } from '../external/browser.service';
import { DesignTemplateService } from '../external/design-template.service';
import { environment } from '../../../environments/environmentDecking';
import { RelevantLoads } from '../../entities/decking-code-list/enums/relevant-loads';
import { DesignTypeId } from '../../entities/enums/design-types';
import { DeckingDesignModeType } from '../../entities/enums/decking-design-mode-type';
import { DeckingMainService } from '../decking-main/decking-main.service';
import { URLPath } from '../application-provider/application-provider.service';
import { RoutingService } from '../external/routing.service';

@Injectable({
    providedIn: 'root'
})
// Service in charge to keep the State of a DeckingUsing using RxJs BehaviorSubject
// Any modification to DeckingDesign, should be performed here using provided deckingDesignSubject.
export class DeckingDesignService {

    responsePromise: Promise<HttpResponse<DeckingDesign>>;
    private _documentId: string;
    private _launchedFromDashboard = false;

    public readonly currentDeckingDesign$: Observable<DeckingDesign>;

    /**
     * @private Observable to watch for changes on current area.
     */
    public readonly currentArea$: Observable<AreaModel>;

    /**
     * @private Observable to watch for changes on current zone
     */
    public readonly currentZone$: Observable<ZoneModel>;

    /**
     * @private Observable to watch for changes on the current settings
     */
    public readonly currentSettings$: Observable<DesignSettings>;

    /**
     * @private Observable to watch for changes on the decking report data
     */
    public readonly currentReportData$: Observable<DeckingReport>;

    /**
     * private Observable to watch for change on the general data of the areas
     */
    public readonly currentAreasSummary$: Observable<AreaSummaryModel[]>;

    public readonly canDeleteZone$: Observable<boolean>;

    /**
     * @private Observable to watch if there are changes to undo.
     */
    public readonly canUndo$: Observable<boolean>;

    /**
     * @private Observable to watch if there are changes to redo.
     */
    public readonly canRedo$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for required shear stiffness input was changed
     */
    public readonly isRequiredShearStiffnessSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for required uplift submittal input was changed
     */
    public readonly isRequiredUpliftSubmittalSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for required substitution shear submittal input was changed
     */
    public readonly isSubstitutionRequiredShearStiffnessSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for required substitution uplift submittal input was changed
     */
    public readonly isSubstitutionRequiredUpliftSubmittalSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for substitution relevant load at zone level was changed
     */
    public readonly isSubstitutionRelevantLoadAtZoneLevelSetting$: Observable<boolean>;

    /**
     * @private Observable to watch if zone inputs for relevant load at zone level was changed
     */
    public readonly isRelevantLoadAtZoneLevelSetting$: Observable<boolean>;

    public readonly currentDocument$: Observable<IDesignListItem>;


    public designAreasZonesNames: Map<string, Set<string>>;

    private _document: BehaviorSubject<IDesignListItem>;
    private currentDesign: DeckingDesign;
    private deckingDesignSubject: BehaviorSubject<DeckingDesign>;

    /**
     * @private Sets the maximum stack size of the undo and redo Stack.
     */
    private readonly MAX_UNDOREDO_STACK_SIZE: number = 200;

    /**
     * @private Sets the suffix of the zone name in case of duplicates.
     */
    private readonly DUPLICATE_AREA_ZONE_NAME_SUFFIX: string = '(1)';

    private undoStack: Array<DeckingDesign> = [];
    private canUndo = new BehaviorSubject<boolean>(false);

    private redoStack: Array<DeckingDesign> = [];
    private canRedo = new BehaviorSubject<boolean>(false);

    private readonly maximumNumberAreas = 10;
    isDesignSettingUpdated: boolean;

    constructor(
        private deckingCodeListService: DeckingCodeListService,
        private designDefaultFactory: DeckingDefaultFactoryService,
        private deckingAreasService: DeckingDesignAreasService,
        private deckingZonesService: DeckingZonesService,
        private apiService: ApiService,
        private documentService: DeckingDocumentService,
        private trackingService: DeckingTrackingService,
        private localizationService: LocalizationService,
        private browserService: BrowserService,
        private designTemplateService: DesignTemplateService,
        private deckingZoneInputsSettingsService: DeckingZoneInputsSettingsService,
        private deckingMainService: DeckingMainService,
        private routingService: RoutingService
    ) {
        this.deckingDesignSubject = new BehaviorSubject({} as DeckingDesign);

        this.currentDeckingDesign$ = this.deckingDesignSubject.asObservable();

        this._document = new BehaviorSubject({} as IDesignListItem);
        this.currentDocument$ = this._document.asObservable();

        this.currentArea$ = this.currentDeckingDesign$.pipe(map((design: DeckingDesign) => {
            return design.areas[design.currentAreaIndex];
        }), shareReplay(1));

        this.currentZone$ = this.currentDeckingDesign$.pipe(map((design: DeckingDesign) => {
            return design.areas[design.currentAreaIndex]?.zones[design.currentZoneIndex];
        }), shareReplay(1));

        this.currentSettings$ = this.currentDeckingDesign$.pipe(map((design: DeckingDesign) => {
            return design.settings;
        }), shareReplay(1));

        this.currentReportData$ = this.currentDeckingDesign$.pipe(map((design: DeckingDesign) => {
            return design.report;
        }), shareReplay(1));

        this.currentAreasSummary$ = this.currentDeckingDesign$.pipe(map((design: DeckingDesign) => {
            return design.areas.map(a => {
                return {
                    id: a.id,
                    name: a.name,
                    eTag: a.eTag,
                };
            });
        }), shareReplay(1));

        this.canDeleteZone$ = this.currentArea$.pipe(map(a => this.deckingZonesService.canDeleteZone(a)), shareReplay(1));
        this.isRequiredShearStiffnessSetting$ = this.currentSettings$.pipe(map(settings => settings.requiredShearStiffness.value), distinctUntilChanged());
        this.isRequiredUpliftSubmittalSetting$ = this.currentSettings$.pipe(map(settings => settings.requiredUpliftSubmittal?.value), distinctUntilChanged());
        this.isRelevantLoadAtZoneLevelSetting$ = this.currentSettings$.pipe(map(settings => settings.windAndSeismicLoadsAtZoneLevel.value), distinctUntilChanged());
        this.isSubstitutionRequiredShearStiffnessSetting$ = this.currentSettings$.pipe(map(settings => settings.substitutionRequiredShearStiffness?.value), distinctUntilChanged());
        this.isSubstitutionRequiredUpliftSubmittalSetting$ = this.currentSettings$.pipe(map(settings => settings.substitutionRequiredUpliftSubmittal?.value), distinctUntilChanged());
        this.isSubstitutionRelevantLoadAtZoneLevelSetting$ = this.currentSettings$.pipe(map(settings => settings.substitutionWindAndSeismicLoadsAtZoneLevel?.value), distinctUntilChanged());
        this.canUndo$ = this.canUndo.asObservable();
        this.canRedo$ = this.canRedo.asObservable();
    }

    public get documentId(): string {
        return this._documentId;
    }
    public set documentId(documentId: string) {
        this._documentId = documentId;
    }

    public get launchedFromDashboard(): boolean {
        return this._launchedFromDashboard;
    }
    public set launchedFromDashboard(launchedFromDashboard: boolean) {
        this._launchedFromDashboard = launchedFromDashboard;
    }
    /**
     * return current document
     */
    public get document() {
        return this._document.value;
    }

    /**
     * Get last area selected.
     */
    public get currentArea(): AreaModel {
        return this.currentDesign.areas[this.currentDesign.currentAreaIndex];
    }

    /**
     * Get last zone selected.
     */
    public get currentZone(): ZoneModel {
        return this.currentDesign.areas[this.currentDesign.currentAreaIndex]?.zones[this.currentDesign.currentZoneIndex];
    }

    /**
     * Get current design synchronously
     */
    public getCurrentDesign(): DeckingDesign {
        return this.currentDesign;
    }

      /**
     * Get current design synchronously
     */
      public updateToExistingDesign() {
          this.currentDesign.isNew = false;
    }

    /**
     * Reset service when there is no current design
     */
    public dispose(): void {
        this.documentId = null;
        this.trackingService.trackDeckingActivity(this.currentDesign);
        this.trackingService.trackDesignClosed(this.currentDesign);
        // Unlocking the design.
        this.updateDocumentDesignContent(this.document, true);
    }

    public async initDeckingDesignCodeList(): Promise<void> {
        await this.deckingCodeListService.init();
    }

    public async loadDeckingDesign(deckingDesignId: string, documentId: string): Promise<DeckingDesign> {
        this.initalizeDocument();
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        // Get Decking Design from decking-design-svc
        const deckingDesign = await this.getDeckingDesignById(deckingDesignId);

        // get document data
        this._document.next(null);
        if (!deckingDesign.isTemplate) {
            this._document.next(this.documentService.findDesignById(documentId));
        }

        // Set saved property to true to avoid calculation
        deckingDesign.saved = true;

        this.documentId = documentId;
        this.setDesign(deckingDesign, true);
        this.resetUndoRedoStacks();
        this.updateEnvironmentBasedInCurrentDesign();

        this.trackingService.trackDesignOpened(deckingDesign);

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        return deckingDesign;
    }

    public async createNewDesign(projectId: string, customName: string = null): Promise<DeckingDesign> {
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();
        // Create New Design in the state
        const deckingDesign = this.designDefaultFactory.buildDefaultDeckingDesign(projectId, customName);

        return await this.loadNewDesign(projectId, deckingDesign);
    }

    public async createNewDesignFromTemplate(projectId: string, designFromTemplate: DeckingDesign): Promise<DeckingDesign> {
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        return await this.loadNewDesign(projectId, designFromTemplate);
    }

    private async loadNewDesign(projectId: string, deckingDesign: DeckingDesign): Promise<DeckingDesign> {
        // Store Design in DocumentService
        // Passing Design as NULL, because we are going to work only with DeckingDesign.
        this._document.next(await this.documentService.addDesign(projectId, null, true, false, true, deckingDesign));
        this.documentId = this.document.id;
        deckingDesign.documentId = this.document.id;
        this.setDesign(deckingDesign);
        this.updateEnvironmentBasedInCurrentDesign();
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        this.trackingService.trackDesignOpened(deckingDesign);

        return deckingDesign;
    }

    public async createNewDesignWithSettings(projectId: string, designSettings: DesignSettings, customName: string = null): Promise<DeckingDesign> {
        await this.initDeckingDesignCodeList();
        // Set region settings for new design
        const settings = cloneDeep(designSettings);
        // Create New Design in the state
        const deckingDesign = this.designDefaultFactory.buildDeckingDesignFromSettings(projectId, settings, customName);

        this._document.next(await this.documentService.addDesign(projectId, null, true, false, true, deckingDesign));
        this.documentId = this.document.id;
        deckingDesign.documentId = this.document.id;
        deckingDesign.name = this.document.designName;
        this.setDesign(deckingDesign);
        this.updateEnvironmentBasedInCurrentDesign();
        this.resetUndoRedoStacks();
        this.updateDesignSettings(designSettings, projectId, deckingDesign.name);

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        this.trackingService.trackDesignOpened(deckingDesign);

        return deckingDesign;
    }

    public async createNewDesignFromExternalFile(projectId: string, deckingDesign: DeckingDesign, comesFromPEFile = false, isNewWindowImport = false): Promise<DeckingDesign> {
        deckingDesign = this.updateDefaultSetting(deckingDesign);
        await this.prepareDeckingDesign(deckingDesign, projectId, comesFromPEFile);

        if (comesFromPEFile) {
            deckingDesign.saved = false;
            deckingDesign.id = uuidv4();
        }
        // Store Design in DocumentService
        // Passing Design as NULL, because we are going to work only with DeckingDesign.
        const newDocument = await this.documentService.addDesign(projectId, null, true, false, true, deckingDesign);
        deckingDesign.documentId = newDocument.id;
        // It saves the design and returns when is a new window import.
        if (isNewWindowImport) {
            await this.saveDesign(deckingDesign);
            return deckingDesign;
        }

        this._document.next(newDocument);
        this.documentId = this.document.id;
        this.setDesign(deckingDesign);
        this.updateEnvironmentBasedInCurrentDesign();
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        this.trackingService.trackDesignOpened(deckingDesign);

        return deckingDesign;
    }

    public updateDefaultSetting(design: DeckingDesign): DeckingDesign {
        if (design.settings.requiredUpliftSubmittal === undefined || design.settings.requiredUpliftSubmittal === null) {
          design.settings.requiredUpliftSubmittal = { value: true };
        }
        if (design.settings.substitutionRequiredShearStiffness === undefined || design.settings.substitutionRequiredShearStiffness === null) {
          design.settings.substitutionRequiredShearStiffness = { value: false };
        }
        if (design.settings.substitutionRequiredUpliftSubmittal === undefined || design.settings.substitutionRequiredUpliftSubmittal === null) {
          design.settings.substitutionRequiredUpliftSubmittal = { value: false };
        }
        if (design.settings.substitutionWindAndSeismicLoadsAtZoneLevel === undefined || design.settings.substitutionWindAndSeismicLoadsAtZoneLevel === null) {
          design.settings.substitutionWindAndSeismicLoadsAtZoneLevel = { value: true }; 
        }
        if(design.settings.designStandard === undefined || design.settings.designStandard === null) {
            design.settings.designStandard = {id: 1,  value: 'AISI S310-20', index: 2};
        }
        // iterate through each area and update setting
        this.updateSettingsInArea(design);
        
        return design;
    }

   public updateSettingsInArea(design: DeckingDesign) {
        design.areas.forEach(area => {
            if(area.definitionOfSidelapConnectors === undefined || area.definitionOfSidelapConnectors === null) {
                area.definitionOfSidelapConnectors = { id: design.settings.definitionOfSidelapConnectors.id, 
                    value: design.settings.definitionOfSidelapConnectors.value, 
                    index: design.settings.definitionOfSidelapConnectors.index
                };
            }
            if(area.sidelapsSpacingSettings === undefined || area.sidelapsSpacingSettings === null) {
                area.sidelapsSpacingSettings = design.settings.sidelapsSpacingSettings;
            }
            if(area.sidelapsNumberSettings === undefined || area.sidelapsNumberSettings === null) {
                area.sidelapsNumberSettings = design.settings.sidelapsNumberSettings;
            }
          });
    }
    
    public async replaceExistingDesign(oldDesign: IDesignListItem, deckingDesign: DeckingDesign, isNewWindowImport = false): Promise<DeckingDesign> {
        deckingDesign = this.updateDefaultSetting(deckingDesign);
        await this.prepareDeckingDesign(deckingDesign, oldDesign.projectId, true);

        // Store Design in DocumentService
        // Passing Design as NULL, because we are going to work only with DeckingDesign.
        deckingDesign.saved = false;
        deckingDesign.id = await this.getDeckingIdFromDocumentId(oldDesign.id);
        deckingDesign.documentId = oldDesign.id;
        const oldDeckingDesign = await this.getDeckingDesignById(deckingDesign.id);
        deckingDesign.eTag = oldDeckingDesign.eTag;

        // It saves the design and returns when is a new window import.
        if (isNewWindowImport) {
            await this.saveDesign(deckingDesign);
            return deckingDesign;
        }

        this._document.next(oldDesign);
        this.documentId = oldDesign.id;
        this.setDesign(deckingDesign);
        this.updateEnvironmentBasedInCurrentDesign();
        this.resetUndoRedoStacks();

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();

        this.trackingService.trackDesignOpened(deckingDesign);

        return deckingDesign;
    }

    public async getProjectType(id: string): Promise<DeckingDesignModeType> {
        const url = `${environment.deckingDesignServiceUrl}api/DesignQuery/GetProjectType/${id}`;
        return (await this.apiService.request<DeckingDesignModeType>(new HttpRequest('GET', url))).body;
    }

    public async saveDesign(deckingDesign: DeckingDesign) {
        deckingDesign.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.result.numberOfEdgeSupportConnections) {
                    zone.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        const urlSave = `${environment.deckingDesignServiceUrl}api/DesignCommand/save`;
        await this.apiService.request<DeckingDesign>(new HttpRequest('POST', urlSave, deckingDesign));
    }

    private async prepareDeckingDesign(deckingDesign: DeckingDesign, projectId: string, comesFromPEFile = false) {
        this.initalizeDocument();
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        if (!comesFromPEFile) {
            // Creating a Settings object with default values, except for design Method,
            // Relevant Load and Sidelap configuration, since they are configured in ProfisDF
            const designMethod = deckingDesign.settings.designMethod;
            const designStandard = deckingDesign.settings.designStandard;
            const relevantLoads = deckingDesign.settings.relevantLoads;
            const definitionOfSidelapConnectors = deckingDesign.settings.definitionOfSidelapConnectors;
            const sidelapsSpacingSettings = deckingDesign.settings.sidelapsSpacingSettings;
            const sidelapsNumberSettings = deckingDesign.settings.sidelapsNumberSettings;
            const defaultDeckingDesign = this.designDefaultFactory.buildDefaultDeckingDesign(projectId);
            deckingDesign.settings = defaultDeckingDesign.settings;
            deckingDesign.settings.designMethod = designMethod;
            deckingDesign.settings.designStandard = designStandard;
            deckingDesign.settings.relevantLoads = relevantLoads;
            deckingDesign.settings.definitionOfSidelapConnectors = definitionOfSidelapConnectors;
            if (sidelapsSpacingSettings) {
                deckingDesign.settings.sidelapsSpacingSettings = sidelapsSpacingSettings;
            }
            if (sidelapsNumberSettings) {
                deckingDesign.settings.sidelapsNumberSettings = sidelapsNumberSettings;
            }
            if (!deckingDesign.name) {
                deckingDesign.name = this.designDefaultFactory.createDeckingDesignName(projectId);
            }
            deckingDesign.report = defaultDeckingDesign.report;
        }
    }

    public async getDeckingIdFromDocumentId(documentId: string, isLock = true): Promise<string> {
        const deckingContent = await this.documentService.getDeckingDesignContent(documentId, this.documentService.openNewSessionForDesign(documentId), documentId, isLock);
        const deckingDesign = JSON.parse(this.browserService.fromBase64(deckingContent.body.filecontent)) as DeckingDesign;
        return deckingDesign.id;
    }

    private initalizeDocument(): void {
        if (!this._document) {
            this._document = new BehaviorSubject({} as IDesignListItem);
        }
    }

    public async copyDesign(deckingDesignId: string, designName: string, projectId: string): Promise<{ id: string; designId: string }> {
        const newDeckingDesign: DeckingDesign = await this.copyDeckingDesign(deckingDesignId, designName);
        newDeckingDesign.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.result.numberOfEdgeSupportConnections) {
                    zone.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        const documentThumbnail = await this.documentService.getDocumentThumbnails([newDeckingDesign.documentId])
        .then(thumbnails => {
            if (thumbnails != null) {
                return thumbnails[newDeckingDesign.documentId];
            }
            return null;
        });
        // Store Design in DocumentService, Passing Design as NULL, because we are going to work only with DeckingDesign.
        const document = await this.documentService.addDesign(projectId, null, true, false, true, newDeckingDesign);
        newDeckingDesign.documentId = document.id;
        // Store new design thumbnail in DocumentService
        if(documentThumbnail != null) {
            this.documentService.uploadDesignImage(newDeckingDesign.documentId, null, documentThumbnail);
        }
        await this.updateDocumentDesignContent(document, true,false,DocumentAccessMode.Open,newDeckingDesign);
        const urlSave = `${environment.deckingDesignServiceUrl}api/DesignCommand/save`;
        await this.apiService.request<DeckingDesign>(new HttpRequest('POST', urlSave, newDeckingDesign));
        return {
            id: document.id,
            designId: newDeckingDesign.id
        };
    }

    public async copyDeckingDesign(deckingDesignId: string, designName: string): Promise<DeckingDesign> {
        const url = `${environment.deckingDesignServiceUrl}api/DesignCommand/copy`;
        const rData = { id: deckingDesignId, name: designName };
        return (await this.apiService.request<DeckingDesign>(new HttpRequest('POST', url, rData))).body;
    }

    public async createDesignTemplate(deckingDesignId: string, isTemplate = false, designName?: string, projectId?: string): Promise<DeckingDesign> {
        if (!designName) {
            designName = this.designDefaultFactory.createDeckingDesignName(projectId);
        }
        const url = `${environment.deckingDesignServiceUrl}api/DesignTemplateCommand`;
        const rData = { id: deckingDesignId, name: designName, isTemplate: isTemplate };
        return (await this.apiService.request<DeckingDesign>(new HttpRequest('POST', url, rData))).body;
    }

    public async deleteDesign(deckingDesignId: string): Promise<void> {
        const url = `${environment.deckingDesignServiceUrl}api/DesignCommand/${deckingDesignId}`;
        await this.apiService.request(new HttpRequest('DELETE', url));
    }

    public setDesign(deckingDesign: DeckingDesign, lockDocument = false): void {
        this.currentDesign = deckingDesign;
        this.emitDesignChanged();
        // The document has been loaded from DocumentServices, so we need to update the content.
        if (this.document) {
            lockDocument ? this.updateDocumentDesignContent(this.document, false,true,DocumentAccessMode.Open)
            : this.updateDocumentDesignContent(this.document);
        }
    }

    public addNewArea(newArea: AreaModel): void {
        if (this.currentDesign.areas.length >= this.maximumNumberAreas) {
            return;
        }
        newArea.name.value = this.checkDuplicateAreaOrZoneName(newArea.name.value);
        this.pushUndoStack(true);
        if (this.currentDesign.areas === undefined) {
            this.currentDesign.areas = [];
        }
        this.currentDesign.areas.push(newArea);
        this.currentDesign.saved = false;
        this.setCurrentArea(this.currentDesign.areas.length - 1);
        this.trackingService.addArea();
        this.trackingService.addZone(newArea.zones.length);
    }

    public deleteArea(index: number): void {
        this.trackingService.removeArea();
        this.trackingService.removeZone(this.currentDesign.areas[index].zones.length);
        this.pushUndoStack(true);
        this.designAreasZonesNames.delete(this.currentDesign.areas[index].name.value);
        this.currentDesign.areas.splice(index, 1);
        this.currentDesign.saved = false;
        this.setCurrentArea(this.currentDesign.areas.length - 1);
    }

    public duplicateArea(index: number): void {
        const area = this.currentDesign.areas[index];
        const areaCloned: AreaModel = Object.assign(new AreaModel(), JSON.parse(JSON.stringify(area)));
        areaCloned.name = { value: format(this.getCopiedAreaPrefixName(), areaCloned.name.value) };
        areaCloned.id = uuidv4();
        this.addNewArea(areaCloned);
    }

    public updateAreas(areasUpdated: AreaModel[], newIndex: number | null = null): void {
        this.pushUndoStack(true);
        this.currentDesign.areas = areasUpdated;
        this.currentDesign.saved = false;
        if (newIndex !== null && newIndex > -1) {
            this.setCurrentArea(newIndex);
        }
        this.emitDesignChanged();
    }

    public updateCurrentArea(area: AreaModel, isDirty = true, isDeflectionCalculationUpdate = false): void {
        if (!this.deckingAreasService.areInputsEquals(this.currentArea, area)) {
            area.isDirty = isDirty;
            area.isDeflectionCalculationUpdate = isDeflectionCalculationUpdate;
            this.designAreasZonesNames.delete(this.currentArea.name.value);
            area.name.value = this.checkDuplicateAreaOrZoneName(area.name.value);
            this.pushUndoStack(true);
            this.currentDesign.areas[this.currentDesign.currentAreaIndex] = { ...area };
            this.currentDesign.saved = false;
            this.emitDesignChanged();
        }
    }

    public checkDuplicateAreaOrZoneName(areaName: string, zoneName = ''): string {
        if (zoneName === '') {
            return this.checkDuplicateAreaName(areaName);
        }
        else {
            return this.checkDuplicateZoneName(areaName, zoneName);
        }
    }

    private initAreasZoneNames(): void {
        if (this.designAreasZonesNames) {
            this.designAreasZonesNames.clear();
        }
        this.designAreasZonesNames = new Map<string, Set<string>>();
        this.designAreasZonesNames.set(this.currentArea.name.value, new Set<string>());
        this.currentDesign.areas.forEach(area => this.designAreasZonesNames.set(area.name.value, new Set(area.zones.map(zone => zone.name.value))));
    }

    private checkDuplicateAreaName(areaName: string): string {
        if(areaName == '') {
            areaName = this.designDefaultFactory.getDefaultArea(this.currentDesign.settings, this.currentDesign.areas.length).name.value;
        }
        if (!this.designAreasZonesNames.has(areaName)) {
            this.designAreasZonesNames.set(areaName, new Set<string>());
        } else {
            while (this.designAreasZonesNames.has(areaName)) {
                areaName = `${areaName} ${this.DUPLICATE_AREA_ZONE_NAME_SUFFIX}`;
            }
            this.designAreasZonesNames.set(areaName, new Set<string>());
        }
        this.currentArea.zones.forEach(z => this.designAreasZonesNames.get(areaName).add(z.name.value));
        return areaName;
    }

    private checkDuplicateZoneName(areaName: string, zoneName: string): string {
        if (!this.designAreasZonesNames.has(areaName)) {
            this.initAreasZoneNames();
        }
        if (this.designAreasZonesNames.has(areaName) && !this.designAreasZonesNames.get(areaName).has(zoneName)) {
            this.designAreasZonesNames.get(areaName).add(zoneName);
        } else {
            while (this.designAreasZonesNames.has(areaName) && this.designAreasZonesNames.get(areaName).has(zoneName)) {
                zoneName = `${zoneName} ${this.DUPLICATE_AREA_ZONE_NAME_SUFFIX}`;
            }
            this.designAreasZonesNames.get(areaName).add(zoneName);
        }
        return zoneName;
    }

    public addNewZoneToCurrentArea(): void {
        this.pushUndoStack(true);
        const defaultZone = this.deckingZonesService.getDefaultZone(this.currentDesign.settings);
        defaultZone.name.value = this.checkDuplicateAreaOrZoneName(this.currentArea.name.value, defaultZone.name.value);
        this.deckingAreasService.addZone(this.currentArea, defaultZone);
        this.updateZones(this.currentArea.zones);
        this.trackingService.addZone();
    }

    public updateZones(zonesUpdated: ZoneModel[]): void {
        this.designAreasZonesNames.get(this.currentArea.name.value).clear();
        zonesUpdated.forEach(z => {
            this.designAreasZonesNames.get(this.currentArea.name.value).add(z.name.value);
        });
        this.currentArea.zones = zonesUpdated;
        this.currentDesign.saved = false;
        this.emitDesignChanged();
    }

    public toggleLockZone(zone: ZoneModel, index: number): void {
        zone.isLocked.value = !zone.isLocked?.value;
        this.updateZone(zone, index);
    }

    public deleteZone(zoneIndex: number): void {
        this.designAreasZonesNames.get(this.currentArea.name.value).delete(this.currentArea.zones[zoneIndex].name.value);
        this.pushUndoStack(true);
        this.deckingAreasService.deleteZone(this.currentArea, zoneIndex);
        if (this.currentDesign.currentZoneIndex === zoneIndex) {
            this.setCurrentZone(0);
        }
        this.updateZoneIndexOnDelete(zoneIndex);
        this.updateZones(this.currentArea.zones);
        this.trackingService.removeZone();
    }

    public updateCurrentZone(zoneUpdated: ZoneModel): void {
        this.updateZone(zoneUpdated, this.currentDesign.currentZoneIndex);
    }

    public updateZone(zoneUpdated: ZoneModel, index: number, isDirty = true, isFastenerEstimationUpdate = false): void {
        if (!this.deckingZonesService.areZoneInputsEqual( // only update is a change was made
            this.currentArea.zones[index],
            zoneUpdated
        )) {
            zoneUpdated.isDirty = isDirty;
            zoneUpdated.isFastenerEstimationUpdate = isFastenerEstimationUpdate;
            this.designAreasZonesNames.get(this.currentArea.name.value).delete(this.currentArea.zones[index].name.value);
            zoneUpdated.name.value = this.checkDuplicateAreaOrZoneName(this.currentArea.name.value, zoneUpdated.name.value);
            this.pushUndoStack(true);
            this.currentArea.zones[index] = zoneUpdated;
            this.currentDesign.saved = false;
            this.emitDesignChanged();
        }
    }

    public getZoneCurrentIndex(zone: ZoneModel): number {
        return this.currentArea.zones.findIndex(x => x.id === zone.id);
    }

    public resetAllAreaZones(
        zonePropertiesToReset: Array<keyof ZoneModel> =
            ['deckGauge', 'pattern', 'frameFastener', 'sidelapConnector', 'side', 'result']
    ) {
        const areasCloned = cloneDeep(this.currentDesign.areas);
        for (const area of areasCloned) {
            this.resetZonePropertiesOfArea(area, zonePropertiesToReset);
        }
        return areasCloned;
    }

    public resetZonePropertiesOfArea(
        area: AreaModel,
        zonePropertiesToReset: Array<keyof ZoneModel> =
            ['deckGauge', 'pattern', 'frameFastener', 'sidelapConnector', 'side', 'result']
    ) {
        const defaultZone = this.deckingZonesService.getDefaultZone(this.currentDesign.settings);
        for (const zone of area.zones) {
            zone.deckGauge = zonePropertiesToReset.includes('deckGauge') ? defaultZone.deckGauge : zone.deckGauge;
            zone.pattern = zonePropertiesToReset.includes('pattern') ? defaultZone.pattern : zone.pattern;
            zone.frameFastener = zonePropertiesToReset.includes('frameFastener') ? defaultZone.frameFastener : zone.frameFastener;
            zone.sidelapConnector = zonePropertiesToReset.includes('sidelapConnector') ? defaultZone.sidelapConnector : zone.sidelapConnector;
            zone.side = zonePropertiesToReset.includes('side') ? defaultZone.side : zone.side;
            zone.result = zonePropertiesToReset.includes('result') ? defaultZone.result : zone.result;
            zone.alternatives = [];
        }
        return area;
    }

    public updatePanel(area: AreaModel): void {

        area.zones.forEach(zone => {
            zone.pattern = this.deckingCodeListService.GetDefaultPatternDropdownItem();
            // Change frame fastener if deck panel is restricted
            if (zone.frameFastener && this.deckingCodeListService.GetFrameFastenerItem(zone.frameFastener.id).restrictedPanels.includes(area.deckPanel.id)) {
                zone.frameFastener = this.deckingCodeListService.GetDefaultFrameFastenerDropdownItem();
            }
            // Change sidelap connector if panel is restricted
            if (zone.sidelapConnector && this.deckingCodeListService.GetSidelapItem(zone.sidelapConnector.id).restrictedPanels.includes(area.deckPanel.id)) {
                zone.sidelapConnector = this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem();
            }
        });
        area.panelWidth = this.deckingCodeListService.GetDefaultPanelWidthDropdownItem(area.deckPanel.id);
        area.panelType = this.deckingCodeListService.GetDefaultPanelTypeDropdownItem(area.deckPanel.id);
        this.updateCurrentArea(area);
    }

    public updatePanelType(area: AreaModel): void {
        area.zones.forEach(zone => {
            // Change sidelap connector if it doesn't apply to panel type
            if (zone.sidelapConnector && !this.deckingCodeListService.GetSidelapItem(zone.sidelapConnector.id).panelTypes.includes(area.panelType.id)) {
                zone.sidelapConnector = this.deckingCodeListService.GetDefaultSidelapConnectorDropdownItem();
            }
        });
        this.updateCurrentArea(area);
    }

    public updatePanelWidth(area: AreaModel): void {
        area.zones.forEach(zone => {
            zone.pattern = this.deckingCodeListService.GetDefaultPatternDropdownItem();
            zone.alternatives = [];
        });
        this.updateCurrentArea(area);
    }

    public updateZoneTypeForZones(relevantLoads: RelevantLoads): void {
        this.currentArea.zones.forEach(zone => {
            zone.relevantLoads = { value: relevantLoads };
            zone.isDirty = true;
        });
        this.updateZones(this.currentArea.zones);
    }

    public setCurrentArea(index: number): void {
        if(index >= 0 && index < this.currentDesign.areas.length){
            this.currentDesign.currentAreaIndex = index;
            this.currentDesign.currentZoneIndex = 0;
            this.currentDesign.saved = false;
            this.emitDesignChanged();
        }
    }

    public setCurrentZone(index: number): void {
        if (index !== this.currentDesign.currentZoneIndex) {
            this.currentDesign.currentZoneIndex = index;
            this.currentDesign.saved = false;
            this.emitDesignChanged();
        }
    }

    public updateZoneIndexOnDelete(indexDeleted: number): void {
        if (this.currentDesign.currentZoneIndex > indexDeleted) {
            this.currentDesign.currentZoneIndex--;
            this.emitDesignChanged();
        }
    }

    public isAreaSelected(index: number): boolean {
        return this.currentDesign.currentAreaIndex === index;
    }

    public isZoneSelected(index: number): boolean {
        return this.currentDesign.currentZoneIndex === index;
    }

    public getCurrentAreaIndex(): number {
        return this.currentDesign.currentAreaIndex;
    }

    public pushUndoStack(clearRedo = false): void {
        if (this.currentDesign) {
            const cloneDesign = cloneDeep(this.currentDesign);
            this.undoStack.push(cloneDesign);
            if (this.undoStack.length > this.MAX_UNDOREDO_STACK_SIZE) {
                this.undoStack.shift();
            }
            if (this.redoStack.length > 0 && clearRedo) {
                this.redoStack = [];
            }
            this.emitUndoRedoState();
        }
    }

    public undoChange(): void {
        if (this.undoStack.length > 0) {
            this.redoStack.push(cloneDeep(this.currentDesign));
            this.propagateMementoDesign(this.undoStack.pop());
        }
    }

    public redoChange(): void {
        if (this.redoStack.length > 0) {
            this.pushUndoStack();
            this.propagateMementoDesign(this.redoStack.pop());
        }
    }

    public async updateDesignSettings(designSettings: DesignSettings, projectId: string, designName: string, isDirty = true, isRelevantLoadsDifferent = false): Promise<void> {
        if (this.currentDesign.settings.definitionOfSidelapConnectors.index !== designSettings.definitionOfSidelapConnectors.index) {
            this.currentDesign.areas = this.resetAllAreaZones(['result', 'side']);
        }
        if(this.document /*Not Template*/){
            const currentDocument = this.document ?? this._document.value;
            currentDocument.designName = designName ?? this.currentDesign.name;
            this._document.next(currentDocument);
        }
        this.isDesignSettingUpdated = true;
        this.updateDocumentDesignContent(this.document);
        this.fixPatternsValues(this.currentDesign.settings.length.id, designSettings.length.id);
        this.currentDesign.settings = designSettings;
        // Update setting for area as well here
        this.currentDesign.areas.forEach(area => {
            area.definitionOfSidelapConnectors = { id: designSettings.definitionOfSidelapConnectors.id, 
              value: designSettings.definitionOfSidelapConnectors.value, 
              index: designSettings.definitionOfSidelapConnectors.index
            };
            area.sidelapsSpacingSettings = designSettings.sidelapsSpacingSettings;
            area.sidelapsNumberSettings = designSettings.sidelapsNumberSettings;
            area.isDirty = true;
            area.isDeflectionCalculationUpdate = false;
          });

        this.currentDesign.eTag = this.deckingDesignSubject.getValue().eTag;
        this.currentDesign.saved = false;
        this.currentDesign.isDirty = isDirty;
        this.deckingZoneInputsSettingsService.checkDesignZoneInputSettings(this.currentDesign, isRelevantLoadsDifferent);
        this.emitDesignChanged();
    }

    public async updateDesignTemplateSettings(designSettings: DesignSettings, templateName: string): Promise<void> {
        this.currentDesign.settings = designSettings;
        this.currentDesign.name = templateName;
        this.currentDesign.areas.forEach(area => {
            area.zones.forEach(zone => {
                if(!zone.result.numberOfEdgeSupportConnections) {
                    zone.result.numberOfEdgeSupportConnections = {value: 0};
                }
            });
        });
        const urlSave = `${environment.deckingDesignServiceUrl}api/DesignCommand/save`;
        await this.apiService.request<DeckingDesign>(new HttpRequest('POST', urlSave, this.currentDesign));
        const template = await this.designTemplateService.getById(this.currentDesign.documentId);
        template.DesignTemplateName = templateName;
        const updatedTemplate: IDesignTemplateDocument = {
            designStandardId: template.DesignStandardId,
            designTypeId: template.DesignTypeId,
            projectDesign: template.ProjectDesign,
            regionId: template.RegionId,
            anchorName: template.AnchorName,
            approvalNumber: template.ApprovalNumber,
            designTemplateDocumentId: template.DesignTemplateDocumentId,
            templateName: template.DesignTemplateName,
            thumbnailImageContent: '',
            templateFolderId: template?.templateFolderId
        };
        await this.designTemplateService.update(updatedTemplate);
        // Gets the recently saved design template and sets it to ensure UI change name update
        const savedDesignTemplate = await this.getDeckingDesignById(this.currentDesign.id);
        this.setDesign(savedDesignTemplate);
    }

    public async updateDesignNameProject(name: string, projectId: string, document: IDesignListItem = null, deckingDesignId: string = null): Promise<void> {
        if (document) {
            this._document.next(document);
        }
        if (name !== this.document.designName || projectId !== this.document.projectId) {
            await this.documentService.updateDesignWithNewContent(this.document.id,
                projectId !== this.document.projectId ? projectId : this.document.projectId,
                name !== this.document.designName ? name : this.document.designName,
                {
                    AnchorPlate: null,
                    CalculatedData: null,
                    CalculationType: null,
                    DesignName: deckingDesignId || this.currentDesign.id, // placeholder for Decking Design Id.
                    Dialogs: null,
                    IntegrationDocument: null,
                    InternalProjectDesignType: null,
                    IsAnchorPlatePresent: null,
                    IsProfilePresent: null,
                    IsStressDistributionVisible: null,
                    Options: null,
                    Profile: null,
                    ProjectDesignType: DesignTypeId.DiaphragmDesign,
                    ProjectDesignVersion: null,
                    ProjectName: null,
                    Stiffeners: null,
                    Welds: null,
                    CreatedWithCheckbot: null
                },
                null, null);
            if (this.currentDesign && name !== this.currentDesign?.name) {
                this.currentDesign.name = name;
            }
        }
    }

    public async getDeckingDesignById(deckingDesignId: string): Promise<DeckingDesign> {
        const url = `${environment.deckingDesignServiceUrl}api/DesignQuery/${deckingDesignId}`;
        this.responsePromise = this.apiService.request<DeckingDesign>(new HttpRequest('GET', url));
        return (await this.responsePromise).body;
    }

    public async reloadDesign(deckingDesign: DeckingDesign, deckingDocument: IDesignListItem): Promise<void> {
        // Populate Dropdowns
        await this.initDeckingDesignCodeList();

        // Set saved property to true to avoid calculation
        deckingDesign.saved = true;

        // Associate Document to Decking Design
        this._document.next(deckingDocument);
        this.documentId = deckingDocument.id;

        this.setDesign(deckingDesign, true);
        this.resetUndoRedoStacks();

        this.trackingService.trackDesignOpened(deckingDesign);

        //Init Areas and Zones Names Collection
        this.initAreasZoneNames();
    }

    public async updateDocumentDesignContent(document: IDesignListItem, unlock = false, exclusiveLock = false, documentAccessMode: DocumentAccessMode = DocumentAccessMode.Open, deckingDesign: DeckingDesign = null): Promise<void> {
        const base64XmlContent = this.browserService.toBase64(deckingDesign ?? this.currentDesign) ;
        await this.documentService.updateDocumentDesignContent(document, base64XmlContent, unlock, exclusiveLock, documentAccessMode);
    }

    public async loadDeckingTemplateFromExternalFile(templateId: string) {
        const design = await this.getDeckingDesignById(templateId);
        this.deckingMainService.setTemplateId(design.documentId);
        const deckingDesign = await this.createDesignTemplate(templateId, false, null, this.documentService.draftsProject.id);
        const newDeckingDesign = await this.createNewDesignFromTemplate(this.documentService.draftsProject.id, deckingDesign);
        this.launchedFromDashboard = true;
        this.routingService.navigateToUrl(URLPath.mainDecking + newDeckingDesign.id);
    }

    private fixPatternsValues(oldLengthId: number, newLengthId: number): void {
        const wasLengthImperial = oldLengthId === Unit.ft || oldLengthId === Unit.inch || oldLengthId === Unit.mi;
        const isLengthImperial = newLengthId === Unit.ft || newLengthId === Unit.inch || newLengthId === Unit.mi;

        if (wasLengthImperial == isLengthImperial) {
            return;
        }

        this.currentDesign.areas.forEach(area => {
            const patterns = this.deckingCodeListService.GetPatternsDropdownItems(area.deckPanel.id, area.panelWidth.value, isLengthImperial);
            area.zones.forEach(zone => {
                if (zone.pattern) {
                    zone.pattern.value = patterns.filter(pattern => pattern.value && pattern.value.id == zone.pattern.id)[0].text;
                }
            });
        });
    }

    private updateEnvironmentBasedInCurrentDesign(): void {
        this.trackingService.resetUsageCounter(this.currentDesign);
        this.setCurrentArea(this.currentDesign.currentAreaIndex);
        this.setCurrentZone(this.currentDesign.currentZoneIndex);
    }

    private emitDesignChanged(): void {
        if (this.currentDesign.saved) {
            this.trackingService.trackDeckingActivity(this.currentDesign);
        }
        this.deckingDesignSubject.next(cloneDeep(this.currentDesign));  // clone to avoid exposing the object directly
    }

    private emitUndoRedoState(): void {
        this.canRedo.next(this.redoStack.length > 0);
        this.canUndo.next(this.undoStack.length > 0);
    }

    private resetUndoRedoStacks(): void {
        this.redoStack = [];
        this.undoStack = [];
        this.emitUndoRedoState();
    }

    /**
     * # Propagates a design as the current design.
     *
     * Takes a mementoDesign from the Undo/Redo stack and propagates it as the current
     * design, setting the etag and saved properties to allow the design to be saved.
     *
     * @param {DeckingDesign} mementoDesign  A saved design that comes from the undo/redo stack.
     */
    private propagateMementoDesign(mementoDesign: DeckingDesign) {
        this.currentDesign = mementoDesign;
        this.currentDesign.eTag = this.deckingDesignSubject.getValue().eTag;
        this.currentDesign.saved = false;
        this.updateEnvironmentBasedInCurrentDesign();
        this.emitDesignChanged();
        this.emitUndoRedoState();
    }

    private getCopiedAreaPrefixName(): string {
        return this.localizationService.getString('Agito.Hilti.Profis3.Decking.AreaManagement.CopiedAreaPrefixName');
    }
}
